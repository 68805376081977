import React, { useState } from 'react';
import { WordData } from './../services/data';

interface FlashcardDisplayProps {
    flashcards: WordData[];
    currentIndex: number;
    translation: string;
    onPrevious: () => void;
    onNext: () => void;
    isNavigating: boolean;
}

const FlashcardDisplay: React.FC<FlashcardDisplayProps> = ({
    flashcards,
    currentIndex,
    translation,
}) => {
    const [showTranslation, setShowTranslationCard] = useState(false);

    const toggleTranslation = () => {
        return setShowTranslationCard(!showTranslation)
    }

    return (
        <section className="flashcard-section">
            {flashcards.length > 0 && translation && (
                <div className="flashcard">
                    <p><strong>Word:</strong> {flashcards[currentIndex].word}</p>
                    <p><strong>Grammar:</strong> {flashcards[currentIndex].grammar_type}</p>
                    <button onClick={toggleTranslation}>{showTranslation ? 'Hide' : 'Show'} Translation</button>
                    {translation && showTranslation && <p><strong>Translation:</strong> {translation}</p>}
                </div>
            )}
        </section>
    );
};

export default FlashcardDisplay;
