// src/Header.tsx
import React from 'react';
import './Header.css'; // Ensure you create a CSS file for styles
import { useAuth } from './AuthProvider';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  const { isAuthenticated, logout, userData } = useAuth();
  const isSubscriptionActive = userData?.isSubscriptionActive;
  return (
    <header className="header">
      <h1 className="header-title"><Link to={'/'}>StoryLengua</Link></h1>
      <nav className="header-nav">
        <a href="/contact" className='contact-link'>Contact</a>

        {/* Show Register and Login links if not authenticated */}
        {!isAuthenticated && (
          <>
            <a href="/register">Register</a>
            <a href="/login">Login</a>
          </>
        )}

        {isAuthenticated && (
          <>
            <a href="/account">Account</a>
            <a href="/story-page">Stories</a>
            <a href="/flashcards">Flashcards</a>
            {!isSubscriptionActive && <a href={`${process.env.REACT_APP_STRIPE_PAYMENT_URL}?client_reference_id=${userData?.id}`} target="_blank" rel="noopener noreferrer">Upgrade</a>}
            <button className="logout-button" onClick={logout}>
              Logout
            </button>
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
