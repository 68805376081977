import React, { useEffect } from 'react';

interface StoryOutputProps {
    story: string;
    language: string;
    activeWordIndex: number | null;
    handleWordClick: (word: string, index: number) => void;
    translatedWord: string;
    translationCardRef: React.RefObject<HTMLLabelElement>;
    wordRefs: React.MutableRefObject<(HTMLElement | null)[]>;
    nonSpacedLanguages: string[];
    setActiveWordIndex: (index: number | null) => void; // Add this to control active state
}

const StoryOutput: React.FC<StoryOutputProps> = ({
    story, language, activeWordIndex, handleWordClick, translatedWord, translationCardRef, wordRefs, nonSpacedLanguages, setActiveWordIndex
}) => {

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (translationCardRef.current && !translationCardRef.current.contains(event.target as Node)) {
                setActiveWordIndex(null); // Close the translation by setting the active word index to null
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [translationCardRef, setActiveWordIndex]);

    return (
        <div className="storyPage-output">
            <h3>Your Story:</h3>
            <p>
                {nonSpacedLanguages.includes(language)
                    ? Array.from(story).map((char, idx) => (
                        <span
                            key={idx}
                            ref={(el) => {
                                if (wordRefs.current) {
                                    wordRefs.current[idx] = el; // Ensure wordRefs is not null before assigning
                                }
                            }}
                            onClick={() => handleWordClick(char, idx)}
                            className={`storyPage_word ${activeWordIndex === idx ? 'active' : ''}`}
                        >
                            {char}
                            {activeWordIndex === idx && (
                                <label className='tooltip' ref={translationCardRef}>
                                    <span className='translation'>{translatedWord}</span>
                                </label>
                            )}
                        </span>
                    ))
                    : story.split(' ').map((word, idx) => (
                        <span
                            key={idx}
                            ref={(el) => {
                                if (wordRefs.current) {
                                    wordRefs.current[idx] = el; // Ensure wordRefs is not null before assigning
                                }
                            }}
                            onClick={() => handleWordClick(word, idx)}
                            className={`storyPage_word ${activeWordIndex === idx ? 'active' : ''}`}
                        >
                            {word}{' '}
                            {activeWordIndex === idx && (
                                <label className='tooltip' ref={translationCardRef}>
                                    <span className='translation'>{translatedWord}</span>
                                </label>
                            )}
                        </span>
                    ))}
            </p>
        </div>
    );
};

export default StoryOutput;
