import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import StoryForm from './StoryForm';
import StoryOutput from './StoryOutput';
import Error from './Error';
import { useAuth } from './AuthProvider';
import { genres, languages, levels, languageCodes, LanguageKey, nonSpacedLanguages } from '../services/data';
import "./StoryPage.css";

const StoryPage: React.FC = () => {
    const [language, setLanguage] = useState<LanguageKey>('English');
    const [level, setLevel] = useState('beginner');
    const [genre, setGenre] = useState('fiction');
    const [story, setStory] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { userData } = useAuth();

    const [targetLanguage, setTargetLanguage] = useState<LanguageKey>("Spanish");
    const [translatedWord, setTranslatedWord] = useState('');
    const [activeWordIndex, setActiveWordIndex] = useState<number | null>(null);
    const [showLink, setShowLink] = useState<boolean>(false);
    const wordRefs = useRef<HTMLElement[]>([]);
    const translationCardRef = useRef<HTMLLabelElement | null>(null);

    useEffect(() => {
        if (language === targetLanguage) {
            const newTargetLanguage = languages.find(lang => lang !== language);
            if (newTargetLanguage) {
                setTargetLanguage(newTargetLanguage as LanguageKey);
            }
        }
    }, [language, targetLanguage]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/generate-story`, {
                language,
                level,
                genre
            }, { withCredentials: true });

            setLoading(false);
            setStory(response.data.story);
        } catch (err: any) {
            setLoading(false);
            if (err.response && err.response.data && err.response.data.message) {
                if (err.response.status === 403) {
                    setShowLink(true)
                }
                setError(err.response.data.message);
            } else {
                setError('Error generating story. Please try again.');
            }
        }
    };

    const handleWordClick = async (word: string, index: number) => {
        setActiveWordIndex(index); // Set the currently active word index
        setTranslatedWord("");
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/translate`, {
                word,
                sourceLang: languageCodes[language],
                targetLang: languageCodes[targetLanguage]
            }, { withCredentials: true });
            setTranslatedWord(response.data.translatedText);
        } catch (err) {
            setError('Error translating word. Please check the input and try again.');
            console.error(err);
        }
    };

    return (
        <div className="storyPage-container">
            <h2 className="storyPage-title">Generate Your Story</h2>
            <StoryForm
                language={language}
                setLanguage={setLanguage}
                level={level}
                setLevel={setLevel}
                genre={genre}
                setGenre={setGenre}
                targetLanguage={targetLanguage}
                setTargetLanguage={setTargetLanguage}
                languages={languages}
                levels={levels}
                genres={genres}
                handleSubmit={handleSubmit}
                loading={loading}
            />
            {error && <Error message={error} autoDismiss={showLink && false} link={showLink ? `${process.env.REACT_APP_STRIPE_PAYMENT_URL}?client_reference_id=${userData?.id}` : ""} />}
            {story && (
                <StoryOutput
                    setActiveWordIndex={setActiveWordIndex}
                    story={story}
                    language={language}
                    activeWordIndex={activeWordIndex}
                    handleWordClick={handleWordClick}
                    translatedWord={translatedWord}
                    translationCardRef={translationCardRef}
                    wordRefs={wordRefs}
                    nonSpacedLanguages={nonSpacedLanguages}
                />
            )}
        </div>
    );
};

export default StoryPage;
