// src/Footer.tsx
import React from 'react';
import './Footer.css'; // Create a CSS file for styles

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <p>&copy; {new Date().getFullYear()} StoryLengua. All rights reserved.</p>
      <nav className="footer-nav">
        <a href="/privacy-policy">Privacy Policy</a>
        <a href="/terms-of-service">Terms of Service</a>
        <a href="/contact">Contact</a>
      </nav>
    </footer>
  );
};

export default Footer;
