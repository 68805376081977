import React, { useState } from 'react';
import './ContactPage.css';
import axios from 'axios';
import Spinner from './Spinner';
import Message from './Message';
import Error from './Error';

const ContactPage: React.FC = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError(''); // Reset error message

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/contact`, formData, { withCredentials: true });

            if (response.status === 200) {
                setMessage(response.data.message);
            }
        } catch (error: any) {
            console.error('Error sending message:', error);
            setError('Failed to send message. Please try again later.');
        } finally {
            setLoading(false);
            setFormData({ name: '', email: '', message: '' });
        }
    }

    return (
        <div className="contact-page-container">
            <h1 className="contact-page-title">Contact Us</h1>
            <form className="contact-form" onSubmit={handleSubmit}>
                {message && <Message message={message}/>}
                {error && <Error message={error}/>}
                
                <div className="form-group">
                    <input
                        type="text"
                        name="name"
                        className="form-input"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <input
                        type="email"
                        name="email"
                        className="form-input"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <textarea
                        name="message"
                        className="form-textarea"
                        placeholder="Your Message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    />
                </div>

                <button type="submit" className="submit-button" disabled={loading}>
                    {loading ? <Spinner /> : 'Send Message'}
                </button>
            </form>
        </div>
    );
};

export default ContactPage;
