import React, { useEffect } from 'react';
import { FlashcardKeys, LanguageKey } from './../services/data';

interface LanguageSelectorProps {
    selectedLanguage: FlashcardKeys;
    targetLanguage: LanguageKey;
    flashcardLanguages: FlashcardKeys[];
    allLanguages: LanguageKey[];
    onSelectLanguage: (language: FlashcardKeys) => void;
    onSelectTargetLanguage: (language: LanguageKey) => void;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
    selectedLanguage,
    targetLanguage,
    flashcardLanguages,
    allLanguages,
    onSelectLanguage,
    onSelectTargetLanguage
}) => {
    // Automatically update the target language if it's the same as the selected language
    useEffect(() => {
        if (targetLanguage === selectedLanguage) {
            const validLanguages = allLanguages.filter(lang => lang !== selectedLanguage);
            if (validLanguages.length > 0) {
                onSelectTargetLanguage(validLanguages[0]); // Set a valid language as the default
            }
        }
    }, [selectedLanguage, targetLanguage, allLanguages, onSelectTargetLanguage]);

    return (
        <section className="language-selectors">
            <div className="selector-column">
                <h3>Select Language</h3>
                <select
                    value={selectedLanguage}
                    onChange={(e) => onSelectLanguage(e.target.value as FlashcardKeys)} // Type assertion
                    className="language-selector"
                >
                    {flashcardLanguages.map((language) => (
                        <option key={language} value={language}>{language}</option>
                    ))}
                </select>
            </div>

            <div className="selector-column">
                <h3>Translate To</h3>
                <select
                    value={targetLanguage}
                    onChange={(e) => onSelectTargetLanguage(e.target.value as LanguageKey)} // Type assertion
                    className="language-selector"
                >
                    {allLanguages.filter(lang => lang !== selectedLanguage).map(lang =>
                        <option key={lang} value={lang}>{lang}</option>
                    )}
                </select>
            </div>
        </section>
    );
};

export default LanguageSelector;
