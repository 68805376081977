// src/HomePage.tsx
import React from 'react';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const HomePage: React.FC = () => {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth();

  return (
    <div className="homepage-container">
      <header className="homepage-header">
        <div className="homepage-header-content">
          <h1 className="homepage-title">StoryLengua</h1>
          <p className="homepage-subtitle">
            Unlock the adventure of language learning!
          </p>
        </div>
      </header>

      <div className="homepage-main">
        <section className="homepage-features">
          <h2 className="homepage-features-title">Why Choose StoryLengua?</h2>
          <div className="homepage-features-list">
            <div className="feature-item">
              <img src="ai-brain2.png" alt="AI-Driven Content" className="feature-icon" />
              <p>AI-driven content generation</p>
            </div>
            <div className="feature-item">
              <img src="green-book.png" alt="Unlimited Genres" className="feature-icon" />
              <p>Unlimited genres to choose from</p>
            </div>
            <div className="feature-item">
              <img src="red-comp.png" alt="Interactive learning" className="feature-icon" />
              <p>Make learning fun and engaging.</p>
            </div>
          </div>
        </section>

        {!isAuthenticated && (
          <section className="homepage-cta">
            <h2 className="homepage-cta-title">Start Your Journey</h2>
            <p className="homepage-cta-text">
              Get your account and enhance your language skills today.
            </p>
            <button className="homepage-cta-button" onClick={() => navigate("/register")}>Sign Up</button>
          </section>
        )}

      </div>
    </div>
  );
};

export default HomePage;
