import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import Header from './Header';
import Footer from './Footer';
import "./App.css";
import Register from './Register';
import Login from './Login';
import StoryPage from './StoryPage';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import { AuthProvider } from './AuthProvider';
import ContactPage from './ContactPage';
import SuccessPage from './SuccessPage';
import Account from './Account';
import Flashcards from './Flashcards';

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/register" element={<Register />} />
              <Route path='/login' element={<Login />} />
              <Route path='/story-page' element={<StoryPage />} />
              <Route path='/terms-of-service' element={<TermsOfService />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/success-page" element={<SuccessPage />} />
              <Route path="/account" element={<Account />} />
              <Route path="/flashcards" element={<Flashcards />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
