export const languages: LanguageKey[] = [
    'English',
    'Spanish',
    'French',
    'German',
    'Italian',
    'Mandarin',
    'Japanese',
    'Korean',
    'Russian',
    'Portuguese',
    'Arabic',
    'Dutch',
    'Swedish',
    'Norwegian',
    'Danish',
    'Finnish',
    'Turkish',
    'Vietnamese',
    'Thai',
    'Greek',
    'Hebrew',
    'Czech',
    'Hungarian',
    'Polish',
    'Indonesian',
    'Filipino',
    'Romanian',
    'Ukrainian',
    'Malay',
    'Slovak',
    'Swahili'
] as LanguageKey[];

export const flashcardLanguagesAvailable: FlashcardKeys[] = [
    'German',
    'Spanish',
    'French',
    'Italian',
    'Russian'
];

export type FlashcardKeys =
    | 'German'
    | "French"
    | 'Spanish'
    | 'Russian'
    | 'Italian'

export const flashcardLanguageCodes: Record<FlashcardKeys, string> = {
    German: 'de',
    French: 'fr',
    Spanish: 'es',
    Russian: 'ru',
    Italian: 'it'
};


export const languageCodes = {
    English: 'en',
    Spanish: 'es',
    French: 'fr',
    German: 'de',
    Italian: 'it',
    Mandarin: 'zh-CN',
    Japanese: 'ja',
    Korean: 'ko',
    Russian: 'ru',
    Portuguese: 'pt',
    Arabic: 'ar',
    Dutch: 'nl',
    Swedish: 'sv',
    Norwegian: 'no',
    Danish: 'da',
    Finnish: 'fi',
    Turkish: 'tr',
    Vietnamese: 'vi',
    Thai: 'th',
    Greek: 'el',
    Hebrew: 'iw', // 'he' is also commonly used
    Czech: 'cs',
    Hungarian: 'hu',
    Polish: 'pl',
    Indonesian: 'id',
    Filipino: 'tl', // or 'fil'
    Romanian: 'ro',
    Ukrainian: 'uk',
    Malay: 'ms',
    Slovak: 'sk',
    Swahili: 'sw'
};


export const nonSpacedLanguages = [
    "Japanese",
    "Mandarin",
    "Korean",
    "Thai",
    "Lao",
    "Burmese",
    "Khmer"
]

export type LanguageKey =
    | 'English'
    | 'Spanish'
    | 'French'
    | 'German'
    | 'Italian'
    | 'Mandarin'
    | 'Japanese'
    | 'Korean'
    | 'Russian'
    | 'Portuguese'
    | 'Arabic'
    | 'Dutch'
    | 'Swedish'
    | 'Norwegian'
    | 'Danish'
    | 'Finnish'
    | 'Turkish'
    | 'Vietnamese'
    | 'Thai'
    | 'Greek'
    | 'Hebrew'
    | 'Czech'
    | 'Hungarian'
    | 'Polish'
    | 'Indonesian'
    | 'Filipino'
    | 'Romanian'
    | 'Ukrainian'
    | 'Malay'
    | 'Slovak'
    | 'Swahili';


export const levels = [
    "Children's Story",
    'Beginner',
    'Intermediate',
    'Advanced'
];

export const genres = [
    'Historical',
    'Biography',
    'Fantasy',
    'Mystery',
    'Romance',
    'Science Fiction',
    'Horror',
    'Adventure',
    'Thriller',
    'Dystopian',
    'Comedy',
    'Drama',
    'Non-Fiction',
    'Young Adult',
    'Children\'s',
    'Poetry',
    'Graphic Novel',
    'Western',
    'Magical Realism',
    'Crime',
    'Action',
    'Slice of Life',
    'Superhero',
    'Cyberpunk',
    'Steampunk',
    'Mythology',
    'Literary Fiction',
    'Classic',
    'Chick Lit',
    'New Adult'
];

export interface WordData {
    word: string;
    grammar_type: string;
}