import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Account.css'; // Make sure to import the CSS file
import { useAuth } from './AuthProvider';
import Spinner from './Spinner';
import Message from './Message';
import Error from './Error';

const Account: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [stripePortalUrl, setStripePortalUrl] = useState<string | null>(null);
    const [error, setError] = useState('');
    const { userData } = useAuth();
    const [username, setUsername] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [message, setMessage] = useState<string>("");

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const parsedData = JSON.parse(storedUserData);
            setUsername(parsedData.username || '');
            setEmail(parsedData.email || '');
        }
    }, []);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/user-data`,
                    { withCredentials: true }
                );
                const { username, email } = response.data.user;
                const existingData = localStorage.getItem('userData')
                    ? JSON.parse(localStorage.getItem('userData') as string)
                    : {};
                console.log(existingData)
                const updatedUserData = {
                    ...existingData,
                    username,
                    email
                };

                localStorage.setItem('userData', JSON.stringify(updatedUserData));
                setEmail(email)
                setUsername(username)
            } catch (err) {
                setError('Failed to load user data');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);


    useEffect(() => {
        const fetchCustomerIdAndGenerateLink = async () => {
            try {
                const storedUserData = localStorage.getItem('userData');
                const parsedUserData = storedUserData ? JSON.parse(storedUserData) : userData;

                const customerId = parsedUserData?.customerId;

                if (customerId) {
                    setUsername(parsedUserData.username);
                    setEmail(parsedUserData.email);

                    const sessionResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/generate-stripe-subscription-btn`, {
                        customerId,
                    }, { withCredentials: true });

                    setStripePortalUrl(sessionResponse.data.url);
                }
            } catch (err) {
                console.error(err);
                setError('Failed to generate the Stripe link.');
            } finally {
                setLoading(false);
            }
        };

        fetchCustomerIdAndGenerateLink();
    }, [userData]);

    const handleUpdate = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setMessage("");
        setError("");

        try {
            const storedUserData = localStorage.getItem('userData');
            const parsedUserData = storedUserData ? JSON.parse(storedUserData) : {};

            // Initialize an empty object to store changed fields
            let updatedFields: { username?: string | null, email?: string | null } = {};

            // Compare current username and email with stored values and update only if changed
            if (username !== parsedUserData.username) {
                updatedFields.username = username;
            }

            if (email !== parsedUserData.email) {
                updatedFields.email = email;
            }

            // If no fields have changed, don't send the request
            if (Object.keys(updatedFields).length === 0) {
                setLoading(false);
                setMessage('No changes made.');
                return;
            }

            // Send the updated fields to the server
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/update-user-data`,
                updatedFields,
                { withCredentials: true }
            );

            if (response.status === 200) {
                setMessage(response.data.message);
                const updatedUserData = {
                    ...parsedUserData,
                    ...updatedFields
                }
                localStorage.setItem('userData', JSON.stringify(updatedUserData));

            } else {
                setError('Failed to update user data.');
            }
        } catch (err: any) {
            console.log(err);
            if (err.response && err.response.data && err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError('An error occurred. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="account-container">
            <div className="account-form-wrapper">
                <h1>Update Your Account</h1>
                {message && <Message message={message} />}
                {error && <Error message={error} />}
                {loading ? <Spinner /> : <form onSubmit={handleUpdate} className='account-form'>
                    <input
                        type="text"
                        value={username || ''}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Update Username"
                        required
                    />
                    <input
                        type="email"
                        value={email || ''}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Update Email"
                        required
                    />
                    <button type="submit">Update</button>
                </form>}
            </div>
            {userData?.customerId &&
                <div className="subscription-section">
                    <h1>Manage Your Subscription</h1>
                    {stripePortalUrl ? (
                        <a href={stripePortalUrl} target="_blank" rel="noopener noreferrer" className="manage-subscription-link">
                            Manage Subscription
                        </a>
                    ) : (
                        <p>Unable to load subscription management link.</p>
                    )}
                </div>
            }
        </div>
    );
};

export default Account;
