import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface UserData {
    id: number;
    username: string;
    email: string;
    isSubscriptionActive: boolean;
    customerId: string;
}

interface AuthContextProps {
    isAuthenticated: boolean;
    userData?: UserData;
    login: (userData: UserData) => void;
    logout: () => void;
    refreshUserData: (updatedData: UserData) => void; // Add refreshUserData to the context
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(localStorage.getItem('isAuthenticated') === 'true');
    const [userData, setUserData] = useState<UserData | undefined>(undefined); // State for user data
    const navigate = useNavigate();

    const login = (data: UserData) => {
        try {
            localStorage.setItem('isAuthenticated', 'true');
            localStorage.setItem('userData', JSON.stringify(data)); // Save user data to local storage
            setIsAuthenticated(true);
            console.log(data)
            setUserData(data);
        } catch (err) {
            console.log(err)
        }
    };

    const logout = async () => {
        try {
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('userData'); // Clear user data from local storage
            await axios.post(`${process.env.REACT_APP_SERVER_URL}/logout`, {}, { withCredentials: true });
            setIsAuthenticated(false);
            setUserData(undefined);
            navigate('/login');
        } catch (err) {
            console.log(err);
        }
    };

    const refreshUserData = (updatedData: UserData) => {
        try {
            console.log(updatedData)
            localStorage.setItem('userData', JSON.stringify(updatedData)); // Update local storage
            setUserData(updatedData); // Update user data in state
        } catch (err) {
            console.log(err)
        }
    };

    // Load user data from local storage on initial load
    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
        }
    }, []);

    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            response => response,
            (error) => {
                if (error.response && error.response.status === 401) {
                    // If session expired or token is invalid, log out
                    logout();
                }
                return Promise.reject(error);
            }
        );
        return () => axios.interceptors.response.eject(interceptor);
        // eslint-disable-next-line
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, userData, login, logout, refreshUserData }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the AuthContext
export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
