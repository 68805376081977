import React, { useEffect, useState } from 'react';
import "./Error.css";

interface ErrorComponent {
    message?: string;
    autoDismiss?: boolean;
    link?: string;
}

const Error: React.FC<ErrorComponent> = ({ message = "An unexpected error occurred", autoDismiss = true, link }: ErrorComponent) => {
    const [visible, setVisible] = useState(true);
    const [fading, setFading] = useState(false);

    useEffect(() => {
        if (autoDismiss) {
            const fadeOutTimer = setTimeout(() => {
                setFading(true);
            }, 4700);

            const hideTimer = setTimeout(() => {
                setVisible(false);
            }, 5000);

            return () => {
                clearTimeout(fadeOutTimer);
                clearTimeout(hideTimer);
            };
        }
    }, [autoDismiss]);

    if (!visible) return null;

    return (
        <div className={`error_message_wrapper ${fading ? 'fading-out' : ''}`}>
            <p className="error_message">{message}
                {link && (
                    <a href={link} className="error_upgrade_link" target="_blank" rel="noopener noreferrer">
                        UPGRADE FOR MORE
                    </a>
                )}
            </p>
        </div>
    );
};

export default Error;
