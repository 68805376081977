import React from 'react';
import { WordData } from '../services/data';

interface FlashcardNavigationProps {
    currentIndex: number;
    flashcards: WordData[];
    setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
    history: number[];
    setHistory: React.Dispatch<React.SetStateAction<number[]>>;
    isNavigating: boolean;
    setIsNavigating: React.Dispatch<React.SetStateAction<boolean>>;
}

const FlashcardNavigation: React.FC<FlashcardNavigationProps> = ({
    currentIndex,
    flashcards,
    setCurrentIndex,
    history,
    setHistory,
    isNavigating,
    setIsNavigating
}) => {

    // Pick a random index for the next flashcard
    const nextCard = () => {
        if (!isNavigating) {
            setIsNavigating(true);
            const newIndex = Math.floor(Math.random() * flashcards.length);
            setHistory([...history, currentIndex]);
            setCurrentIndex(newIndex);
            setTimeout(() => setIsNavigating(false), 2000);
        }
    };

    // Navigate to the previous flashcard using history
    const previousCard = () => {
        if (history.length > 0 && !isNavigating) {
            setIsNavigating(true);
            const prevIndex = history[history.length - 1];
            setHistory(history.slice(0, -1));
            setCurrentIndex(prevIndex);
            setTimeout(() => setIsNavigating(false), 2000);
        }
    };

    return (
        <footer className="flashcard-navigation">
            <button onClick={previousCard} disabled={currentIndex === 0 || isNavigating} className="nav-button">Previous</button>
            <button onClick={nextCard} disabled={currentIndex === flashcards.length - 1 || isNavigating} className="nav-button">Next</button>
        </footer>
    );
};

export default FlashcardNavigation;
