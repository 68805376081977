import React, { useEffect, useState } from 'react';
import "./Message.css";

interface MessageProps {
    message?: string;
}

const Message: React.FC<MessageProps> = ({ message = "Operation successful!" }) => {
    const [visible, setVisible] = useState(true);
    const [fading, setFading] = useState(false);

    useEffect(() => {
        const fadeOutTimer = setTimeout(() => {
            setFading(true);
        }, 4700);

        const hideTimer = setTimeout(() => {
            setVisible(false);
        }, 5000);

        return () => {
            clearTimeout(fadeOutTimer);
            clearTimeout(hideTimer);
        };
    }, []);

    if (!visible) return null;

    return (
        <div className={`message_wrapper ${fading ? 'fading-out' : ''}`}>
            <p className="message_text">{message}</p>
        </div>
    );
};

export default Message;
