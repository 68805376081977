import React, { useState } from 'react';
import './Register.css';
import axios from 'axios';
import Spinner from './Spinner';
import Error from './Error';
import Message from './Message';
import { Link } from 'react-router-dom';

const Register: React.FC = () => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const clearFormData = () => {
        setFormData({
            username: '',
            email: '',
            password: '',
            confirmPassword: '',
        });
    };
    

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match');
            setLoading(false);
            return;
        }

        setError('');
        setSuccess('');

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/register`,
                {
                    username: formData.username,
                    email: formData.email,
                    password: formData.password,
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );

            if (response.status === 201) {
                setSuccess(response.data.message);
                clearFormData()
            }
        } catch (err: any) {
            if (err.response && err.response.data && err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError('An error occurred. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };



    return (
        <div className="register-container">
            <form className="register-form" onSubmit={handleSubmit}>
                <h2 className="register-title">Register</h2>

                {error && <Error message={error} />}
                {success && <Message message={success} />}

                {loading ? <Spinner /> : <>
                    <div className="form-group">
                        <input
                            placeholder='username'
                            type="text"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <input
                            placeholder='email'
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <input
                            placeholder='password'
                            type="password"
                            name="password"
                            minLength={8}
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <input
                            placeholder='confirm password'
                            type="password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <button type="submit" className="register-button">Sign Up</button></>}
                    <p className='terms_message'>By creating an account you agree to our <Link to="/terms-of-service">terms of service</Link></p>
            </form>
        </div>
    );
};

export default Register;
