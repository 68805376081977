// PrivacyPolicy.tsx
import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy: React.FC = () => {
    const date = new Date().toLocaleDateString()
    return (
        <div className="privacyPolicy-container">
            <h1 className="privacyPolicy-title">Privacy Policy</h1>
            <p className="privacyPolicy-intro">
                Effective date: {date}
            </p>
            <p className="privacyPolicy-content">
                Welcome to StoryLengua! This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
            </p>
            
            <h2 className="privacyPolicy-subtitle">1. Information We Collect</h2>
            <p className="privacyPolicy-content">
                We may collect information about you in a variety of ways, including:
            </p>
            <ul className="privacyPolicy-list">
                <li>Personal Data: Personally identifiable information, such as your name, email address, and contact information, that you voluntarily give to us when you register on the site.</li>
                <li>Usage Data: Information about how you use our website and services, including your IP address, browser type, and pages visited.</li>
                <li>Cookies: We may use cookies and similar tracking technologies to track the activity on our service and store certain information.</li>
            </ul>
            
            <h2 className="privacyPolicy-subtitle">2. How We Use Your Information</h2>
            <p className="privacyPolicy-content">
                We use the information we collect in various ways, including:
            </p>
            <ul className="privacyPolicy-list">
                <li>To provide, operate, and maintain our website.</li>
                <li>To improve, personalize, and expand our website and services.</li>
                <li>To understand and analyze how you use our website and services.</li>
                <li>To develop new products, services, features, and functionality.</li>
                <li>To communicate with you for customer service and updates.</li>
                <li>To monitor and analyze user activity using Google Analytics.</li>
            </ul>

            <h2 className="privacyPolicy-subtitle">3. Google Analytics</h2>
            <p className="privacyPolicy-content">
                We use Google Analytics to monitor and analyze user activity on our website. Google Analytics collects data such as your IP address, browser type, pages visited, and time spent on the site. You can opt out of Google Analytics tracking by using the <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-Out Browser Add-on</a>.
            </p>

            <h2 className="privacyPolicy-subtitle">4. Cookies and Tracking Technologies</h2>
            <p className="privacyPolicy-content">
                We may use cookies and similar tracking technologies to enhance your experience on our site. You can manage your cookie preferences through your browser settings. However, disabling cookies may affect your ability to use certain features of our website.
            </p>

            <h2 className="privacyPolicy-subtitle">5. User Rights</h2>
            <p className="privacyPolicy-content">
                You have the right to access, correct, or delete your personal information at any time. If you wish to exercise these rights, please contact us at <a className='privacy_policy_email' href="mailto:contact@storylengua.com">contact@storylengua.com</a>.
            </p>

            <h2 className="privacyPolicy-subtitle">6. Data Retention</h2>
            <p className="privacyPolicy-content">
                We retain your personal information only as long as necessary to provide you with our services and fulfill the purposes outlined in this Privacy Policy.
            </p>

            <h2 className="privacyPolicy-subtitle">7. Children’s Privacy</h2>
            <p className="privacyPolicy-content">
                Our services are not directed to individuals under the age of 13. We do not knowingly collect personal data from children under 13. If we become aware that we have collected personal data from a child under this age, we will take steps to delete such information.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
