import React, { useEffect } from 'react';
import axios from 'axios';
import { flashcardLanguageCodes, languageCodes, FlashcardKeys, LanguageKey, WordData } from './../services/data';

interface FlashcardTranslationProps {
    selectedLanguage: FlashcardKeys;
    targetLanguage: LanguageKey;
    currentIndex: number;
    flashcards: WordData[];
    setTranslation: React.Dispatch<React.SetStateAction<string>>;
}

const FlashcardTranslation: React.FC<FlashcardTranslationProps> = ({
    selectedLanguage,
    targetLanguage,
    currentIndex,
    flashcards,
    setTranslation
}) => {

    useEffect(() => {
        const fetchTranslation = async () => {
            if (flashcards.length > 0) {
                const currentWord = flashcards[currentIndex].word;
                const sourceLang = flashcardLanguageCodes[selectedLanguage];
                const targetLang = languageCodes[targetLanguage];

                try {
                    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/translate`, {
                        word: currentWord,
                        sourceLang: sourceLang,
                        targetLang: targetLang
                    }, { withCredentials: true });

                    setTranslation(response.data.translatedText);
                } catch (error) {
                    console.error("Error fetching translation:", error);
                }
            }
        };

        fetchTranslation();
    }, [currentIndex, targetLanguage, flashcards, selectedLanguage, setTranslation]);

    return null; // This component doesn't render anything, it just handles fetching data
};

export default FlashcardTranslation;
