import './TermsOfService.css'; // Assuming you have a CSS file for styling

const TermsOfService = () => {
    return (
        <div className="tos-container">
            <header className="tos-header">
                <h1 className="tos-title">Terms of Service</h1>
            </header>
            <section className="tos-content">
                <h2 className="tos-section-title">1. Introduction</h2>
                <p className="tos-paragraph">
                    Welcome to StoryLengua! These Terms of Service govern your use of our website and services. 
                    By accessing or using our services, you agree to these terms.
                </p>

                <h2 className="tos-section-title">2. User Accounts</h2>
                <p className="tos-paragraph">
                    To use certain features of our services, you may need to create an account. You are responsible for maintaining the confidentiality of your account information.
                </p>

                <h2 className="tos-section-title">3. User Content</h2>
                <p className="tos-paragraph">
                    You are solely responsible for any content you generate through our services. By using our platform, you grant us a non-exclusive license to use your content.
                </p>

                <h2 className="tos-section-title">4. Limitation of Liability</h2>
                <p className="tos-paragraph">
                    StoryLengua is not liable for any damages arising from your use of our services. 
                    We provide our services on an "as-is" basis.
                </p>

                <h2 className="tos-section-title">5. Changes to Terms</h2>
                <p className="tos-paragraph">
                    We reserve the right to modify these terms at any time. We will notify you of any changes 
                    by posting the new terms on our website.
                </p>

                <h2 className="tos-section-title">6. Contact Us</h2>
                <p className="tos-paragraph">
                    If you have any questions about these Terms of Service, please contact us at 
                    <a href='mailto:contact@storylengua.com'>contact@storylengua.com</a>
                </p>
            </section>
        </div>
    );
};

export default TermsOfService;
