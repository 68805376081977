import React, { useState } from 'react';
import axios from 'axios';
import Spinner from './Spinner'; // Import the Spinner component
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import Error from './Error';

const Login: React.FC = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null); // Reset error
        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/login`, formData, { withCredentials: true });
            login(response.data.user)
            navigate("/");
        } catch (err: any) {
            setError(err.response?.data?.message || 'Error logging in. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleSubmit}>
                <h2 className="login-title">Login</h2>

                {error && <Error message={error}/>}

                {loading ? <Spinner /> : <> <div className="form-group">
                    <input
                        placeholder='email'
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>

                    <div className="form-group">
                        <input
                            placeholder='password'
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit" className="login-button">
                        Login
                    </button></>}
            </form>
        </div>
    );
};

export default Login;
