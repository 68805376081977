import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Flashcards.css';
import { FlashcardKeys, WordData, LanguageKey, languageCodes, flashcardLanguagesAvailable } from './../services/data';
import LanguageSelector from './LanguageSelector';
import FlashcardDisplay from './FlashcardDisplay';
import FlashcardTranslation from './FlashcardTranslation';
import Spinner from './Spinner';
import FlashcardNavigation from './FlashcardNavigation';

const Flashcards: React.FC = () => {
    const flashcardLanguages: FlashcardKeys[] = flashcardLanguagesAvailable;
    const allLanguages: LanguageKey[] = Object.keys(languageCodes) as LanguageKey[];
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedLanguage, setSelectedLanguage] = useState<FlashcardKeys>('French');
    const [targetLanguage, setTargetLanguage] = useState<LanguageKey>('English');
    const [flashcards, setFlashcards] = useState<WordData[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [history, setHistory] = useState<number[]>([]); // Track flashcard history
    const [translation, setTranslation] = useState<string>('');
    const [isNavigating, setIsNavigating] = useState<boolean>(false);

    // Fetch flashcards when the selected language changes
    useEffect(() => {
        const fetchFlashcards = async () => {
            setLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/flashcards?language=${selectedLanguage.toLowerCase()}`,
                    { withCredentials: true }
                );
                const parsedData = JSON.parse(response.data[0].content);
                setFlashcards(parsedData.words_data); // No shuffle here
                setCurrentIndex(0);
                setHistory([]); // Reset history when a new language is selected
                setTranslation(''); // Reset translation when language changes
            } catch (error) {
                console.error("Error fetching flashcards:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchFlashcards();
    }, [selectedLanguage]);

    return (
        <div className="flashcards-container">
            <LanguageSelector
                selectedLanguage={selectedLanguage}
                targetLanguage={targetLanguage}
                flashcardLanguages={flashcardLanguages}
                allLanguages={allLanguages}
                onSelectLanguage={setSelectedLanguage}
                onSelectTargetLanguage={setTargetLanguage}
            />

            {loading ? (
                <Spinner />
            ) : (
                <>
                    <FlashcardDisplay
                        flashcards={flashcards}
                        currentIndex={currentIndex}
                        translation={translation}
                        isNavigating={isNavigating}
                        onPrevious={() => setCurrentIndex(currentIndex - 1)} // Adjust the logic if needed
                        onNext={() => setCurrentIndex(currentIndex + 1)} // Adjust the logic if needed
                    />
                    <FlashcardTranslation
                        selectedLanguage={selectedLanguage}
                        targetLanguage={targetLanguage}
                        currentIndex={currentIndex}
                        flashcards={flashcards}
                        setTranslation={setTranslation}
                    />
                    <FlashcardNavigation
                        currentIndex={currentIndex}
                        flashcards={flashcards}
                        setCurrentIndex={setCurrentIndex}
                        history={history}
                        setHistory={setHistory}
                        isNavigating={isNavigating}
                        setIsNavigating={setIsNavigating}
                    />
                </>
            )}
        </div>
    );
};

export default Flashcards;
