import React from 'react';
import { LanguageKey } from '../services/data';
import Spinner from './Spinner';

interface StoryFormProps {
    language: LanguageKey;
    setLanguage: (lang: LanguageKey) => void;
    level: string;
    setLevel: (level: string) => void;
    genre: string;
    setGenre: (genre: string) => void;
    targetLanguage: LanguageKey;
    setTargetLanguage: (lang: LanguageKey) => void;
    languages: LanguageKey[];
    levels: string[];
    genres: string[];
    handleSubmit: (e: React.FormEvent) => void;
    loading: boolean;
}

const StoryForm: React.FC<StoryFormProps> = ({
    language, setLanguage, level, setLevel, genre, setGenre, targetLanguage, setTargetLanguage,
    languages, levels, genres, handleSubmit, loading
}) => {
    return (
        <form onSubmit={handleSubmit}>
            <label className="storyPage-label">Language</label>
            <select className="storyPage-input" value={language} onChange={(e) => setLanguage(e.target.value as LanguageKey)} required>
                <option value="" disabled>Select Language</option>
                {languages.map((lang) => (
                    <option key={lang} value={lang}>{lang}</option>
                ))}
            </select>

            <label className="storyPage-label">Level</label>
            <select className="storyPage-input" value={level} onChange={(e) => setLevel(e.target.value)} required>
                <option value="" disabled>Select Level</option>
                {levels.map((lvl) => (
                    <option key={lvl} value={lvl}>{lvl}</option>
                ))}
            </select>

            <label className="storyPage-label">Genre</label>
            <select className="storyPage-input" value={genre} onChange={(e) => setGenre(e.target.value)} required>
                <option value="" disabled>Select Genre</option>
                {genres.map((gen) => (
                    <option key={gen} value={gen}>{gen}</option>
                ))}
            </select>

            <label className="storyPage-label">Translate To (optional)</label>
            <select className="storyPage-input" value={targetLanguage} onChange={(e) => setTargetLanguage(e.target.value as LanguageKey)}>
                <option value="" disabled>Select Language</option>
                {languages.filter(lang => lang !== language).map(lang =>
                    <option key={lang} value={lang}>{lang}</option>
                )}
            </select>

            {loading ? <Spinner /> : <button type="submit" className="storyPage-button" disabled={loading}>
                Generate Story
            </button>}

        </form>
    );
};

export default StoryForm;
