import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthProvider';
import './SuccessPage.css';
import Spinner from './Spinner';

const SuccessPage = () => {
    const [isSubscribed, setIsSubscribed] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(true);
    const [countdown, setCountdown] = useState<number>(7); // 5 seconds countdown
    const { refreshUserData } = useAuth();

    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/check-payment`, { withCredentials: true });
                refreshUserData(response.data.user);
                setIsSubscribed(response.data.user.isSubscriptionActive);
            } catch (error) {
                console.error('Error checking payment session:', error);
            } finally {
                setLoading(false);
            }
        };
        checkSession();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            window.location.href = '/story-page'; // Redirect to story page
        }
    }, [countdown]);

    if (loading) {
        return (
           <Spinner />
        );
    }

    return (
        <div className="success-container">
            <h1 className="congrats-message">
                {isSubscribed ? '🎉 Congratulations!' : 'Payment Failed!'}
            </h1>
            {isSubscribed && (
                <>
                    <p className="welcome-message">Your subscription is now active! Ready to explore stories?</p>
                    <p className="countdown-message">You will be redirected to the story page in {countdown} seconds.</p>
                </>
            )}
            {isSubscribed && (
                <a href="/story-page" className="story-link">Start Generating Stories</a>
            )}
        </div>
    );
};

export default SuccessPage;
